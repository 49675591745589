import { FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles({
    root: {
        alignItems: "baseline",
        justifyContent: "space-evenly",
        padding: 12
    }
})

export function RatingRadioGroup({ label, value, onChange, readOnly }) {
    const options = ["1", "2", "3", "4", "5"];
    const classes = useStyle();

    return (
        <>
            <Typography variant="subtitle2">{label}</Typography>
            <RadioGroup className={classes.root} row value={value} onChange={onChange} >
                <Typography variant="subtitle2">Low</Typography>
                {options.map(
                    (o) =>
                        <FormControlLabel
                            key={o}
                            value={o}
                            label={o}
                            labelPlacement="top"
                            control={<Radio disabled={readOnly} />}
                        />
                )
                }
                <Typography variant="subtitle2">High</Typography>
            </RadioGroup>
        </>
    )

}