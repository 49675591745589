import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative"
    },
    divider: {
        width: "100%",
        marginRight: "100%",
        top: "26px",
        position: "absolute",
        borderTop: `1.5px solid ${theme.palette.primary.main}`,
        "&.pending":{
            borderTop: "1.5px solid #cfcfcf"
        }
    },
    step: {
        height: 64,
        width: 64,
        borderRadius: 54,
        backgroundClip: "content-box",
        backgroundColor: "white",
        marginBottom: "8px",
        zIndex: 1
    },
    title: {
        lineHeight: "20px",
        letterSpacing: "0.42px",
        fontWeight: 600,
        cursor: "pointer"
    },
    active: {
        color: theme.palette.text.disabled,
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.19)",
        "& h1": {
            lineHeight: "24px"
        }
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
        cursor: "pointer",
        padding: 14
    },
    pending: {
        padding: 14,
        color: theme.palette.text.disabled,
    },
    pendingTitle: {
        color: theme.palette.text.disabled,
        fontWeight: "normal",
        cursor: "default"
    }
}))

export function StageNumber({ title, stageNumber, state, changeStage }) {
    const classes = useStyles();

    if (state === "completed") {
        return (
            <Grid container className={classes.container} direction="column" alignItems="center" justify="center">
                <Grid item container className={`${classes.step} ${classes.completed}`} direction="column" justify="center" alignItems="center" onClick={() => changeStage()}>
                    <CheckIcon htmlColor="white" fontSize="small" />
                </Grid>
                {(stageNumber === "01") ? null : <hr className={classes.divider}></hr>}
                <Typography variant="body1" className={classes.title} color="primary" onClick={() => changeStage()}>{title}</Typography>
            </Grid>
        )
    }
    else if (state === "pending") {
        return (<Grid container className={classes.container} direction="column" alignItems="center" justify="center">
            <Grid item container color="primary" className={`${classes.step} ${classes.pending}`} direction="column" justify="center" alignItems="center">
                <Typography variant="subtitle2">{stageNumber}</Typography>
            </Grid>
            {(stageNumber === "01") ? null : <hr className={`${classes.divider} pending`}></hr>}
            <Typography variant="body1" className={`${classes.title} ${classes.pendingTitle}`}>{title}</Typography>
        </Grid>
        );
    }

    return (
        <Grid container className={classes.container} direction="column" alignItems="center">
            <Grid item container className={`${classes.step} ${classes.active}`} direction="column" justify="center" alignItems="center" wrap="nowrap" >
                <Typography variant="subtitle2">Step</Typography>
                <Typography color="primary" variant="h1">{stageNumber}</Typography>
            </Grid>
            {(stageNumber === "01") ? null : <hr className={classes.divider}></hr>}
            <Typography variant="body1" className={classes.title}>{title}</Typography>
        </Grid>
    )

}