import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import { approveSubmission, rejectSubmission } from "../api/reviewApi";
import { FilledTextField } from "../shared/FilledTextField";
import SnackbarContext from "../shared/SnackbarContext";

const useStyle = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialogTitle-root": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
    "& .MuiFormControl-root": {
      marginTop: 1,
    },
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "calc(100% - 24px)",
    },
    "& .MuiDialogActions-root": {
      padding: "0px 16px 12px 16px",
      "& .MuiButton-root": {
        minWidth: 90,
        fontWeight: "bold",
        textTransform: "none",
        letterSpacing: "1.26px",
        "&.MuiButton-outlinedPrimary": {
          "&.Mui-disabled": {
            border: "1px solid #aeaeb6",
          },
        },
      },
    },
  },
}));

export default function ProgressSubmission({
  open,
  setOpen,
  action,
  submission,
  onSuccess,
}) {
  const handleClose = () => setOpen(false);
  const commentRef = useRef();
  const classes = useStyle();
  const message = useContext(SnackbarContext);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const onProgress = () => {
    setSaveInProgress(true);
    const invokeAction =
      action === "approve"
        ? approveSubmission(submission.inventionId, commentRef.current.value)
        : rejectSubmission(submission.inventionId, commentRef.current.value);

    invokeAction
      .then((resp) => {
        handleClose();
        onSuccess();
      })
      .catch((error) => {
        console.error(error.response);
        message.showMessage(error.response.data.message);
        setSaveInProgress(false);
      });
  };

  if (submission === null) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth="sm"
    >
      <DialogTitle>
        {action === "approve" ? "Approve Submission" : "Reject Submission"}
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">
          Please enter reason for{" "}
          {action === "approve" ? "approving" : "rejecting"}{" "}
          <i>{submission.title}</i>.
        </Typography>
        <FilledTextField
          inputRef={commentRef}
          autoFocus
          multiline
          maxLength={512}
          rows={5}
        ></FilledTextField>
      </DialogContent>
      <DialogActions>
        <Button disabled={saveInProgress} color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={saveInProgress}
          color="primary"
          variant="outlined"
          onClick={onProgress}
        >
          {action === "approve" ? "Approve" : "Reject"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
