import React, { useContext, useEffect, useImperativeHandle, useRef } from "react";
import { Grid } from "@material-ui/core";
import { FilledTextField } from "../../shared/FilledTextField";
import InventionContext from "../../shared/InventionContext";

export const Comments = React.forwardRef(({ setValidity }, ref) => {
  const commentsRef = useRef();
  const { invention, isReadOnly } = useContext(InventionContext);

  useImperativeHandle(ref, () => ({
    getData() {
      return {
        comments: commentsRef.current.value,
      };
    },
  }));

  useEffect(() => {
    commentsRef.current.value = invention.comments;
    setValidity(true);
  });

  return (
    <Grid container spacing={3}>
      <FilledTextField readOnly={isReadOnly} label="Additional Notes/Comments:"
        multiline={true} rows={3} defaultLen={invention?.comments?.length} maxLength={2000}
        inputRef={commentsRef} />
    </Grid>
  );
});
