import React, { useEffect, useImperativeHandle, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    group: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row"
    },
    helper: {
        display: "flex",
        marginLeft: 14,
        marginRight: 14,
        justifyContent: "space-between"
    }
});

export const BooleanTextField = React.forwardRef(({ label, required, onDataChanged, maxLength, readOnly, ...rest }, ref) => {
    const [selected, setSelected] = useState(null);
    const [text, setText] = useState("");
    const classes = useStyles();
    const [helperText, setHelperText] = useState((required ? '* required' : ''));
    const [charLen, setCharLen] = useState(0);

    useImperativeHandle(ref, () => ({
        isValid() {
            if (selected === false) return true;
            if (selected === true) {
                return (required) ? (!!text) : true;
            }
            return false;
        },
        setValue(isSelected, val) {
            setSelected(isSelected);
            setCharLen(val?.length || 0);
            setText(val || "");
        },
        textValue() {
            return (selected) ? text : null;
        },
        booleanValue() {
            return (selected);
        }
    }));

    useEffect(() => {
        if (onDataChanged) onDataChanged();
        setCharLen(text.length);
    }, [selected, text]);

    const onSelectedChanged = (ev) => {
        setSelected(ev.target.value === "true");
    }

    const onTextChanged = (ev) => {
        setText(ev.target.value);
    }

    return (
        <>
            <Typography variant="subtitle2">{label}</Typography>
            <RadioGroup value={selected} onChange={onSelectedChanged} className={classes.group}>
                <FormControlLabel value={true} control={<Radio disabled={readOnly} />} label={<Typography variant="subtitle2">Yes</Typography>} />
                <FormControlLabel value={false} control={<Radio disabled={readOnly} />} label={<Typography variant="subtitle2">No</Typography>} />
                {(required) ? <Typography variant="body1">* required</Typography> : null}
            </RadioGroup>
            {(selected) ?
                <>
                    <TextField required={required} fullWidth margin="none" type="text" variant="filled" {...rest}
                        value={text} onChange={onTextChanged} InputProps={{ readOnly: readOnly, disableUnderline: readOnly }}
                        inputProps={{ maxLength: maxLength }}
                    ></TextField>
                    {(readOnly) ? null :
                        <div className={classes.helper}>
                            <Typography variant="body1">{helperText || ''}</Typography>
                            {(maxLength) ? <Typography variant="body1">{charLen}/{maxLength} </Typography> : null}
                        </div>
                    }
                </> :

                null}

        </>
    );
})