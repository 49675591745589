import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string'
import UserContext from './UserContext';

export const TokenHandler = () => {

    const history = useHistory();
    const context = useContext(UserContext);

    useEffect(() => {
        const qs = queryString.parse(history.location.hash);
        const id_token = (qs.id_token);
        const access_token = (qs.access_token);
        if (qs.error) {
            alert(qs.error_description);
        }
        else {
            context.updateToken(access_token, id_token);
            history.push('/');
        }
    }, []);

    return (
        <></>
    )

};