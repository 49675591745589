import React from 'react';

export default React.createContext(
    {
        invention: {},
        onNextStage: (payload) => {},
        onSaveAsDraft: (payload) => {},
        displayMessage: (msg) => {},
        lastStageNumber : 7,
        setShowProgress: () => {}
    }
);