import { api } from "./api";
const baseUrl = "/inventions";

export function upload(payload) {
  return api.post(`${baseUrl}/upload`, payload);
}

export function createInvention(title, technology, sectors, userSector) {
  return api.post(baseUrl, {
    title: title,
    technology: technology,
    sectors: sectors,
    userSector: userSector
  });
}

export function updateInvention(id, title, technology, sectors, userSector) {
  return api.put(`${baseUrl}/${id}`, {
    inventionId: id,
    title: title,
    technology: technology,
    sectors: sectors,
    userSector: userSector
  });
}

export function updateInventionStage(inventionId, stageId, payload) {
  return api.put(`${baseUrl}/${inventionId}/${stageId}`, payload);
}

export function updateInventionAsDraft(inventionId, stageId, payload) {
  return api.put(`${baseUrl}/${inventionId}/${stageId}/draft`, payload);
}

export function getInvention(id) {
  return api.get(`${baseUrl}/${id}`).then((resp) => resp.data);
}

export function getMyInventions() {
  return api.get(`${baseUrl}/history`);
}

export function deleteInventionById(id) {
  return api.delete(`${baseUrl}/${id}`);
}

export function getSectors() {
  return api.get(`${baseUrl}/sectors`).then((resp) => resp.data);
}

export function uploadFiles(inventionId, files) {
  const formData = new FormData();

  Array.from(files).forEach(
    (f) => formData.append(f.name, f, f.name)
  );

  return api.post(`${baseUrl}/${inventionId}/upload`, formData).then((resp) => resp.data);
}

export function deleteAttachment(inventionId, attachmentId) {
  return api.delete(`${baseUrl}/${inventionId}/${attachmentId}`).then((resp) => resp.data);
}

export function downloadAttachment(inventionId, attachmentId, fileName) {
  return api.get(`${baseUrl}/${inventionId}/${attachmentId}/download`, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
}
