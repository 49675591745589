import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyle = makeStyles({
    label: {
        lineHeight: "20px",
        letterSpacing: "0.42px",
        marginBottom: "20px"
    },
    heading: {
        paddingTop: "18px",
        paddingBottom: "18px"
    }
})

export function Instructions() {
    const classes = useStyle();
    return (
        <>
            <Typography variant="h6" className={classes.heading} >Instructions</Typography>
            <Typography variant="subtitle2" className={classes.label} >
                Please follow the next steps providing the requested information.
            </Typography>
            <Typography variant="subtitle2" className={classes.label}>
                Check back here to see the current status of your submission.
            </Typography>
        </>
    )
}