import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, FormLabel, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import InventionContext from "../../shared/InventionContext";
import { FilledTextField } from "../../shared/FilledTextField";
import { deleteAttachment, uploadFiles, downloadAttachment } from "../../api/inventionsApi";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { useConfirm } from "material-ui-confirm";
import SnackbarContext from "../../shared/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  removeIcon: {
    marginLeft: 5,
    cursor: "pointer"
  },
  confirm: theme.confirm,
  button: {
    textTransform: "none",
    fontWeight: "bold",
    letterSpacing: "1.26px",
    width: "150px",
    marginTop: "5px"
  },
  info: {
    lineHeight: "20px"
  }
}));

const VALID_URL_PATTERN = new RegExp("^(https://(docs.google.com|drive.google.com)/)(.+)", "i");

export const LinkToDocuments = React.forwardRef(
  ({ setValidity }, ref) => {
    const [attachments, setAttachments] = useState([]);
    const videoLinkRef = useRef();
    const presentationLinkRef = useRef();
    const [isVideoLinkValid, setIsVideoLinkValid] = useState({ validPattern: true, hasValue: false });
    const [isPresentationLinkValid, setIsPresentationLinkValid] = useState({ validPattern: true, hasValue: false });
    const { invention, isReadOnly, setShowProgress } = useContext(InventionContext);
    const message = useContext(SnackbarContext);

    const classes = useStyles();
    const confirm = useConfirm();

    const isValid = () => {
      return (isVideoLinkValid.validPattern && isPresentationLinkValid.validPattern &&
        isVideoLinkValid.hasValue && isPresentationLinkValid.hasValue);
    };

    const validateControls = () => {
      setIsVideoLinkValid({ validPattern: !videoLinkRef.current.value || VALID_URL_PATTERN.test(videoLinkRef.current.value), hasValue: videoLinkRef.current.checkValidity() });
      setIsPresentationLinkValid({ validPattern: !presentationLinkRef.current.value || VALID_URL_PATTERN.test(presentationLinkRef.current.value), hasValue: presentationLinkRef.current.checkValidity() });
    };

    useEffect(() => {
      setValidity(isValid());
    }, [isVideoLinkValid, isPresentationLinkValid]);

    useImperativeHandle(ref, () => ({
      getData() {
        return {
          videoLink: videoLinkRef.current.value,
          presentationLink: presentationLinkRef.current.value
        };
      },
    }));

    const onFileChange = (ev) => {
      const file = ev.target.files[0];
      if (file.size > (10 * 1024 * 1024)) {
        message.showMessage(`File size should be less than 10 MB.`);
        return;
      }
      setShowProgress(true);
      uploadFiles(invention.inventionId, ev.target.files)
        .then(
          (response) => setAttachments(response)
        )
        .finally(
          () => setShowProgress(false)
        )
    }

    const downloadFile = (ev, attachment) => {
      ev.preventDefault();
      downloadAttachment(attachment.inventionId, attachment.attachmentId, attachment.fileName);
    }

    const removeAttachment = (attachment) => {
      confirm({
        dialogProps: {
          maxWidth: "xs",
          className: classes.confirm
        },
        title: "Remove attachment?",
        description: `Are you sure to delete the attachment ${attachment.fileName}?`,
        cancellationText: "Cancel",
        confirmationText: "Ok",
        confirmationButtonProps: { variant: "outlined" },
        cancellationButtonProps: { color: "primary" }
      }).then(
        () => {
          setShowProgress(true);
          deleteAttachment(attachment.inventionId, attachment.attachmentId)
            .then(
              (resp) => setAttachments(resp)
            )
            .finally(
              () => setShowProgress(false)
            )
        }
      )
    }

    useEffect(() => {
      videoLinkRef.current.value = invention.videoLink;
      presentationLinkRef.current.value = invention.presentationLink;
      setAttachments(invention.attachments);
      validateControls();
    }, [])

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormLabel>
            <Typography color="textSecondary" variant="subtitle2">Attachments related to invention:</Typography>
            {(isReadOnly) ? null :
              <Typography className={classes.info} color="textSecondary" variant="subtitle2">*Upload upto 5 files with max of 10MB each</Typography>}
          </FormLabel>
          {(isReadOnly) ? null : <Button disabled={attachments.length >= 5} className={classes.button} component="label" color="primary" variant="outlined"> Upload files
            <input type="file" hidden onChange={onFileChange}></input>
          </Button>
          }
          <ul>
            {
              (attachments.map(a =>
                <li key={a.attachmentId}>
                  <Grid container alignItems="center">
                    <Link href="/" onClick={(ev) => downloadFile(ev, a)} >
                      <Typography variant="subtitle2">{a.fileName}</Typography>
                    </Link>
                    {(isReadOnly) ? null :
                      <CancelRoundedIcon className={classes.removeIcon} color="error" fontSize="small" onClick={() => removeAttachment(a)} />
                    }
                  </Grid>
                </li>
              ))}
          </ul>
        </Grid>
        <Grid item xs={12}>
          <FilledTextField readOnly={isReadOnly} label="Link to Video (Google Drive Link)"
            inputRef={videoLinkRef}
            error={!isReadOnly && !isVideoLinkValid.validPattern}
            errorMessage={'Please a enter valid Google drive url.'}
            onChange={validateControls} />
        </Grid>
        <Grid item xs={12}>
          <FilledTextField readOnly={isReadOnly} label="Link to Presentation (Google Doc/Sheet/Slides or Google Drive Link for any other sort of document)"
            inputRef={presentationLinkRef}
            error={!isReadOnly && !isPresentationLinkValid.validPattern}
            errorMessage={'Please a enter valid Google drive url.'}
            onChange={validateControls} />
        </Grid>
      </Grid>
    );
  }
);
