import React, { useContext, useRef, useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { GeneralDetails } from "./details/generalDetails";
import { Inventors } from "./details/inventors";
import { InventionHistory } from "./details/inventionHistory";
import { InventionDescription } from "./details/inventionDescription";
import { Benefits } from "./details/benefits";
import { LinkToDocuments } from "./details/linkToDocuments";
import { Comments } from "./details/comments";
import InventionContext from "../shared/InventionContext";

const useStyles = makeStyles({
  container: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
    paddingBottom: 24,
    marginTop: 12
  },
  header: {
    height: 64,
    backgroundColor: "#005f9e",
    color: "white",
    paddingLeft: 24,
  },
  content: {
    minHeight: 100,
    paddingTop: 42,
    paddingBottom: 42,
    paddingLeft: 24,
    paddingRight: 24,
  },
  footer: {
    paddingRight: 24,
  },
  button: {
    textTransform: "none",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "1.26px",
    width: "150px"
  }
});

export function InventionStageDetails({ stageNumber, goToNextStage, goToPreviousStage }) {
  const classes = useStyles();
  const [isValid, setValidity] = useState(false);
  const { onNextStage, onSaveAsDraft, lastStageNumber, invention } = useContext(InventionContext);
  const ref = useRef();

  const nextStage = () => {
    onNextStage(ref.current.getData());
  };

  const saveAsDraft = () => {
    onSaveAsDraft(ref.current.getData());
  }

  const getFormByStage = () => {

    if (stageNumber === 1) {
      return <GeneralDetails ref={ref} setValidity={setValidity} />;
    } else if (stageNumber === 2) {
      return <Inventors ref={ref} setValidity={setValidity} />;
    } else if (stageNumber === 3) {
      return <InventionHistory ref={ref} setValidity={setValidity} />;
    } else if (stageNumber === 4) {
      return <InventionDescription ref={ref} setValidity={setValidity} />;
    } else if (stageNumber === 5) {
      return <Benefits ref={ref} setValidity={setValidity} />;
    } else if (stageNumber === 6) {
      return (
        <LinkToDocuments
          ref={ref}
          setValidity={setValidity}
        />
      );
    } else if (stageNumber === 7) {
      return <Comments ref={ref} setValidity={setValidity} />;
    }

    return null;
  };

  const getTitleByStage = () => {
    if (stageNumber === 1) {
      return "General Invention Disclosure Details";
    } else if (stageNumber === 2) {
      return "Inventors";
    } else if (stageNumber === 3) {
      return "Invention History";
    } else if (stageNumber === 4) {
      return "Description of Invention";
    } else if (stageNumber === 5) {
      return "Benefits Estimate";
    } else if (stageNumber === 6) {
      return "Links/Documents";
    }
    return "Comments";
  };

  return (
    <Grid container className={classes.container}>
      <Grid container alignItems="center" item xs={12} className={classes.header}>
        <Typography variant="h3">{getTitleByStage()}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {getFormByStage()}
      </Grid>
      { (invention.status === 1) ?
        <Grid item xs={12} container justify="flex-end" className={classes.footer}>
          <Button className={classes.button} variant="text" color="primary" disabled={stageNumber <= invention?.completedStageNumber && !isValid} onClick={saveAsDraft}>Save as Draft</Button>
          {(lastStageNumber === stageNumber) ?
            <Button className={classes.button} disabled={!isValid} color="primary" variant="contained" onClick={nextStage}>Submit</Button> :
            <Button className={classes.button} disabled={!isValid} color="primary" variant="outlined" onClick={nextStage}>Continue</Button>
          }
        </Grid> :
        <Grid item xs={12} container justify="flex-end" className={classes.footer}>
          <Button className={classes.button} variant="text" color="primary" disabled={stageNumber === 1} onClick={goToPreviousStage}>Back</Button>
          <Button className={classes.button} disabled={stageNumber === lastStageNumber} color="primary" variant="outlined" onClick={goToNextStage}>Next</Button>
        </Grid>
      }
    </Grid>
  );
}