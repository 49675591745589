import React, { useContext, useEffect, useImperativeHandle, useRef } from "react";
import {
  Grid,
} from "@material-ui/core";
import { FilledTextField } from "../../shared/FilledTextField";
import InventionContext from "../../shared/InventionContext";

export const InventionDescription = React.forwardRef(({ setValidity }, ref) => {
  const summaryRef = useRef();
  const advantagesRef = useRef();
  const techinacalDetailsRef = useRef();
  const {invention, isReadOnly} = useContext(InventionContext);

  useImperativeHandle(ref, () => ({
    getData() {
      return {
        summary: summaryRef.current.value,
        advantages: advantagesRef.current.value,
        techinacalDetails: techinacalDetailsRef.current.value,
      };
    },
  }));

  useEffect(() =>{
    summaryRef.current.value = invention.summary;
    advantagesRef.current.value = invention.advantages;
    techinacalDetailsRef.current.value = invention.techinacalDetails;
    setValidity(true);
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FilledTextField readOnly={isReadOnly} label="Brief summary of the Invention (one or two sentences):"
          multiline rows={3} defaultLen={invention?.summary?.length} maxLength={2000} inputRef={summaryRef} />
      </Grid>
      <Grid item xs={12}>
        <FilledTextField readOnly={isReadOnly} label="Describe advantages to previous solutions:"
          multiline rows={3}  defaultLen={invention?.advantages?.length} maxLength={2000} inputRef={advantagesRef} />
      </Grid>
      <Grid item xs={12}>
        <FilledTextField readOnly={isReadOnly} label="Describe how this invention works, provide technical details:"
          multiline rows={3} defaultLen={invention?.techinacalDetails?.length} maxLength={2000} inputRef={techinacalDetailsRef} />
      </Grid>
    </Grid>
  );
});
