import React, { useContext, useEffect, useImperativeHandle, useRef } from "react";
import {
  Grid
} from "@material-ui/core";
import { FilledTextField } from "../../shared/FilledTextField";
import InventionContext from "../../shared/InventionContext";
import { BooleanTextField } from "../../shared/BooleanTextField";

export const Inventors = React.forwardRef(({ setValidity }, ref) => {
  const inventorsDetailsRef = useRef();
  const nonUSTrimbleEntityRef = useRef();
  const employedByRef = useRef();
  const consultingAgreementRef = useRef();
  const { invention, isReadOnly } = useContext(InventionContext);

  useImperativeHandle(ref, () => ({
    getData() {
      return {
        inventorsDetails: inventorsDetailsRef.current.value,
        nonUSTrimbleEntity: nonUSTrimbleEntityRef.current.textValue(),
        hasNonUSTrimbleEntity: nonUSTrimbleEntityRef.current.booleanValue(),
        employedBy: employedByRef.current.textValue(),
        hasNonTrimbleEmployee: employedByRef.current.booleanValue(),
        consultingAgreement: consultingAgreementRef.current.textValue(),
        hasConsultingAgreement: consultingAgreementRef.current.booleanValue()
      };
    },
  }));

  useEffect(() => {
    inventorsDetailsRef.current.value = invention.inventorsDetails;
    nonUSTrimbleEntityRef.current.setValue(invention.hasNonUSTrimbleEntity, invention.non_USTrimbleEntity);
    employedByRef.current.setValue(invention.hasNonTrimbleEmployee, invention.employedBy);
    consultingAgreementRef.current.setValue(invention.hasConsultingAgreement, invention.consultingAgreement);
    setValidity(true);
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FilledTextField readOnly={isReadOnly} label="List the inventor details as separate row in the format as : Inventor Name, Contact Number, Location"
          multiline={true} rows={3} defaultLen={invention?.inventorsDetails?.length} maxLength={2000} margin="none" inputRef={inventorsDetailsRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Are any of the Inventors employed by a non-US Trimble entity or by a recognized Trimble Company? 
            If 'Yes', pls describe the details , else mark 'No'"
          multiline rows={3} maxLength={2000} ref={nonUSTrimbleEntityRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Are any of the Inventors not employed by Trimble? If 'Yes', pls describe the details , else mark 'No'"
          multiline rows={3} maxLength={2000} ref={employedByRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Is there a consulting agreement in place for any non-Trimble employees? If 'Yes', please describe: IP Manager (if known) or Department Manager, else mark 'No'"
          multiline rows={3} maxLength={2000} ref={consultingAgreementRef} />
      </Grid>
    </Grid>
  );
});
