const config = {
  baseUrl: "https://localhost:5001/api",
  loginUrl: "https://localhost:5001/api/auth/login",
  returnUrl: "http://localhost:3000/auth/callback",
};

const configStg = {
  baseUrl: "https://api.ip-mytrimbleinnovation-dev.trimble.com/api",
  loginUrl: "https://api.ip-mytrimbleinnovation-dev.trimble.com/api/auth/login",
  returnUrl: "http://ip-mytrimbleinnovation-dev.trimble.com/auth/callback",
};

const configProd = {
  baseUrl: "https://api.ip-mytrimbleinnovation.trimble.com/api",
  loginUrl: "https://api.ip-mytrimbleinnovation.trimble.com/api/auth/login",
  returnUrl: "http://ip-mytrimbleinnovation.trimble.com/auth/callback",
};

export default process.env.REACT_APP_ENV === "staging"
  ? configStg
  : process.env.REACT_APP_ENV === "production"
  ? configProd
  : config;
