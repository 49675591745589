import { SvgIcon } from "@material-ui/core";
import React from 'react';

export function EditInventionIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M7,15 L9,17 L6,18 L7,15 Z M14.5,7.5 L16.5,9.5 L9.5,16.5 L7.5,14.5 L14.5,7.5 Z M16,6 C17.0543618,6 17.9181651,6.81587779 17.9945143,7.85073766 L18,8 L17,9 L15,7 L16,6 Z">

            </path>
        </SvgIcon>
    );
};