import React, { useContext, useEffect, useImperativeHandle, useRef } from "react";
import {
  Grid,
} from "@material-ui/core";

import { BooleanTextField } from "../../shared/BooleanTextField";
import InventionContext from "../../shared/InventionContext";

export const InventionHistory = React.forwardRef(({ setValidity }, ref) => {
  const commercialUsageDetailsRef = useRef();
  const exhibitDetailsRef = useRef();
  const publicationDetailsRef = useRef();
  const thirdPartyDetailsRef = useRef();
  const inventionBuiltDetailsRef = useRef();
  const salesInformationRef = useRef();
  const { invention, isReadOnly } = useContext(InventionContext);

  useImperativeHandle(ref, () => ({
    getData() {
      return {
        commercialUsageDetails: commercialUsageDetailsRef.current.textValue(),
        exhibitDetails: exhibitDetailsRef.current.textValue(),
        publicationDetails: publicationDetailsRef.current.textValue(),
        salesInformation: salesInformationRef.current.textValue(),
        thirdPartyDetails: thirdPartyDetailsRef.current.textValue(),
        inventionBuiltDetails: inventionBuiltDetailsRef.current.textValue(),
        hasCommercialUsage: commercialUsageDetailsRef.current.booleanValue(),
        hasExhibit: exhibitDetailsRef.current.booleanValue(),
        hasPublication: publicationDetailsRef.current.booleanValue(),
        hasSales: salesInformationRef.current.booleanValue(),
        hasThirdParty: thirdPartyDetailsRef.current.booleanValue(),
        hasBuilt: inventionBuiltDetailsRef.current.booleanValue()
      };
    },
  }));

  useEffect(() => {
    commercialUsageDetailsRef.current.setValue(invention.hasCommercialUsage, invention.commercialUsageDetails);
    exhibitDetailsRef.current.setValue(invention.hasExhibit, invention.exhibitDetails);
    publicationDetailsRef.current.setValue(invention.hasPublication, invention.publicationDetails);
    salesInformationRef.current.setValue(invention.hasSales, invention.salesInformation);
    thirdPartyDetailsRef.current.setValue(invention.hasThirdParty, invention.thirdPartyDetails);
    inventionBuiltDetailsRef.current.setValue(invention.hasBuilt, invention.inventionBuiltDetails);
    setValidity(true);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Has this invention been in commercial use anywhere? if 'Yes', pls explain, else mark 'No'"
          multiline rows={3} maxLength={2000} ref={commercialUsageDetailsRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Has this invention been shown to anyone outside the company or is being planned to? if 'Yes', pls explain, else mark 'No'"
          multiline rows={3} maxLength={2000} ref={exhibitDetailsRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Has this invention been described in a publication or external presentation? if 'Yes', pls explain, else mark 'No'"
          multiline rows={3} maxLength={2000} ref={publicationDetailsRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Has a product including your invention been announced (even if under NDA), offered for sale, or sold? if 'Yes', pls explain, else mark 'No'"
          multiline rows={3} maxLength={2000} ref={salesInformationRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Is this invention part of a joint work with a 3rd party? if 'Yes', pls
          explain, else mark 'No'"
          multiline rows={3} maxLength={2000} ref={thirdPartyDetailsRef} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField readOnly={isReadOnly} label="Has this invention been built? If 'Yes', please explain, else mark 'No'"
          multiline rows={3} maxLength={2000} ref={inventionBuiltDetailsRef} />
      </Grid>
    </Grid>
  );
});
