import {
  AppBar,
  Container,
  createMuiTheme,
  CssBaseline,
  IconButton,
  makeStyles,
  Snackbar,
  SnackbarContent,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { LogoIcon } from "./icons/LogoIcon";
import { UserProfileIcon } from "./icons/UserProfileIcon";
import UserContext from "./authentication/UserContext";
import useLocalStorage from "react-use-localstorage";
import ProtectedRoute from "./authentication/ProtectedRoute";
import jwt_decode from "jwt-decode";
import { TokenHandler } from "./authentication/TokenHandler";
import SnackbarContext from "./shared/SnackbarContext";
import { ListInventions } from "./components/listInventions";
import { Invention } from "./components/invention";
import { HelpOutlineRounded } from "@material-ui/icons";
import { CustomPopover } from "./shared/CustomPopover";
import ListReviews from "./components/listReviews";

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&:hover": {
          backgroundColor: "#005f9e0a",
        },
        "&$checked": {
          color: "#005f9e",
          "&:hover": {
            backgroundColor: "#005f9ea",
          },
        },
      },
    },
    MuiFilledInput: {
      underline: {
        "&:before": {
          borderBottom: "none",
        },
      },
      root: {
        backgroundColor: "#f3f3f7",
        "&:hover": {
          backgroundColor: "#f3f3f7",
        },
        "&:before": {
          borderBottom: "none",
        },
      },
      input: {
        padding: "19px 16px 17px 16px",
        fontSize: 14,
        lineHeight: "20px",
        height: 20,
        "&:read-only:after": {
          borderBottom: "none",
        },
      },
      multiline: {
        padding: 7,
      },
      inputMultiline: {
        height: "auto",
        padding: "16px",
      },
    },
    MuiButton: {
      textTransform: "none",
      text: {
        textTransform: "none",
      },
      outlinedPrimary: {
        border: "1px solid #005f9e",
      },
    },
    MuiDataGrid: {
      root: {
        border: "none",
        borderRadius: 5,
        marginTop: 24,
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor: "#cfe1ee",
          maxHeight: "48px !important",
          minHeight: "48px !important",
        },
        "& .MuiDataGrid-window": {
          top: "48px !important",
        },
        "& .MuiDataGrid-row": {
          marginTop: 12,
          backgroundColor: "#f9f9fb",
          height: 48,
          maxHeight: "48px !important",
          minHeight: "48px !important",
          borderRadius: 5,
          "&:hover": {
            backgroundColor: "#f9f9fb",
          },
        },
        "& .MuiDataGrid-colCellTitle": {
          fontWeight: "bold",
          fontSize: 14,
          lineHeight: 24,
          letterSpacing: 0.28,
          color: "#005f9e",
        },
        "& .MuiDataGrid-cell": {
          fontSize: 14,
          lineHeight: 20,
          letterSpacing: 0.42,
          color: "#262533",
          borderBottom: "none",
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14
      },
    },
  },
  palette: {
    text: {
      disabled: "#8c8b96",
      primary: "#363545",
      secondary: "#262533",
    },
    primary: {
      main: "#005f9e",
    },
    error: {
      main: "#A51D25",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    h1: {
      fontSize: 24,
      lineHeight: "40px",
      letterSpacing: 0.15,
      fontWeight: 900,
    },
    h2: {
      fontSize: 18,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 900,
    },
    h3: {
      fontSize: 18,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 800,
    },
    h4: {
      fontSize: 18,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 600,
    },
    h5: {
      fontSize: 18,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 400,
    },
    h6: {
      fontSize: 16,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 800,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 400,
    },
    body1: {
      fontSize: 12,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 400,
    },
    body2: {
      fontSize: 10,
      lineHeight: "28px",
      letterSpacing: 0.28,
      fontWeight: 400,
    },
  },
  confirm: {
    "& .MuiDialogTitle-root": {
      "& .MuiTypography-root": {
        fontSize: 18,
      },
    },
    "& .MuiDialogContentText-root": {
      fontSize: 14,
      lineHeight: "20px",
      color: "black",
    },
    "& .MuiDialogActions-root": {
      padding: "12px 16px",
      "& .MuiButton-root": {
        minWidth: 90,
        fontWeight: "bold",
        // "&.MuiButton-outlinedPrimary": {
        //   border: "1px solid #005f9e",
        // },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(1),
  },
  container: {
    paddingTop: 60,
  },
  navigation: {
    cursor: "pointer",
    paddingLeft: 12,
    flexGrow: 1,
  },
  profileIcon: {
    height: 36,
    width: 36,
    paddingLeft: 0,
  },
  logoIcon: {
    "& .MuiIconButton-label": {
      "& .MuiSvgIcon-root": {
        width: 132,
        height: 37,
      },
    },
  },
}));

function App() {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [user, setUser] = useLocalStorage(
    "user-info",
    JSON.stringify({
      userInfo: {},
      isLoggedIn: false,
      isApprover: false
    })
  );

  const [idToken, setIdToken] = useLocalStorage("id-token", "");
  const [accessToken, setAccessToken] = useLocalStorage("access-token", "");

  const navigateToHome = (ev) => {
    window.location.replace("/");
  };

  const updateToken = (access_token, id_token) => {
    setIdToken(id_token);
    setAccessToken(access_token);
    updateUserInfo(jwt_decode(id_token));
  };

  const userDetails = JSON.parse(user);
  const [email, setEmail] = React.useState(userDetails.userInfo.Email);
  const [userName, setuserName] = React.useState(userDetails.userInfo.Name);

  const updateUserInfo = (decodedToken) => {
    const userInfo = {
      Email: decodedToken["Email"], 
      Name: decodedToken["Name"],
      Roles: decodedToken["Roles"]
    };

    setEmail(decodedToken["Email"]);
    setuserName(decodedToken["Name"]);

    setUser(
      JSON.stringify({
        userInfo: userInfo,
        isLoggedIn: true,
        isApprover: userInfo.Roles.indexOf("approver") > -1
      })
    );
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserContext.Provider
          value={{
            userInfo: JSON.parse(user).userInfo,
            isLoggedIn: JSON.parse(user).isLoggedIn,
            isApprover: JSON.parse(user).isApprover,
            updateUser: updateUserInfo,
            updateToken: updateToken,
          }}
        >
          <ConfirmProvider>
            <SnackbarContext.Provider
              value={{
                showMessage: (msg) => {
                  setShowMessage(true);
                  setMessage(msg);
                },
                hideMessage: (msg) => {
                  setShowMessage(false);
                  setMessage(null);
                },
              }}
            >
              <AppBar position="static">
                <Toolbar>
                  <IconButton
                    className={classes.logoIcon}
                    onClick={navigateToHome}
                    color="inherit"
                  >
                    <LogoIcon />
                  </IconButton>
                  <Typography variant="h4">|</Typography>
                  <Typography
                    onClick={navigateToHome}
                    variant="h4"
                    className={classes.navigation}
                  >
                    My Trimble Innovation
                  </Typography>
                  <CustomPopover
                    content={
                      <div className={classes.popover}>
                        <Typography variant="subtitle2">{userName}</Typography>
                        <Typography variant="subtitle2">{email}</Typography>
                      </div>
                    }
                  >
                    <IconButton className={classes.profileIcon} color="inherit">
                      <UserProfileIcon
                        fontSize="large"
                        viewBox="0 0 36 36"
                      ></UserProfileIcon>
                    </IconButton>
                  </CustomPopover>
                  <CustomPopover
                    content={
                      <Typography
                        variant="subtitle2"
                        className={classes.popover}
                      >
                        Contact legalip-support-ug@trimble.com for any
                        questions.
                      </Typography>
                    }
                  >
                    <IconButton className={classes.profileIcon} color="inherit">
                      <HelpOutlineRounded fontSize="small"></HelpOutlineRounded>
                    </IconButton>
                  </CustomPopover>
                </Toolbar>
              </AppBar>
              <Container className={classes.container} maxWidth="lg">
                <BrowserRouter>
                  <Route
                    path="/auth/callback"
                    exact
                    component={TokenHandler}
                  ></Route>
                  <ProtectedRoute
                    path="/"
                    exact
                    component={ListInventions}
                  ></ProtectedRoute>
                  <ProtectedRoute
                    path="/inventions/:id"
                    exact
                    component={Invention}
                  ></ProtectedRoute>
                  <ProtectedRoute
                    path="/reviews"
                    exact
                    component={ListReviews}
                  ></ProtectedRoute>
                </BrowserRouter>
              </Container>
              <Snackbar
                open={showMessage}
                autoHideDuration={5000}
                onClose={() => setShowMessage(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <SnackbarContent
                  message={
                    <Typography variant="subtitle2">{message}</Typography>
                  }
                />
              </Snackbar>
            </SnackbarContext.Provider>
          </ConfirmProvider>
        </UserContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
