import React, { useEffect } from 'react';
import { useContext } from "react"
import UserContext from "./UserContext"
import { Route } from 'react-router';
import config from '../config';

export default function ProtectedRoute({ component: Component, ...rest }) {
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (!userContext.isLoggedIn) {
            window.document.location.href = `${config.loginUrl}/?returnUrl=${config.returnUrl}`;
        }
    }, [userContext.isLoggedIn]);

    return (
        <Route {...rest} render={(props) => (
            (userContext.isLoggedIn) ?
                <Component {...props} /> :
                null)}
        />
    )
}