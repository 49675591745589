import React, { useContext, useEffect, useImperativeHandle, useRef } from "react";
import { FilledTextField } from '../../shared/FilledTextField';

import {
  Grid,
} from "@material-ui/core";
import InventionContext from "../../shared/InventionContext";
import { BooleanTextField } from "../../shared/BooleanTextField";

export const GeneralDetails = React.forwardRef(({ setValidity }, ref) => {
  const countryRef = useRef();
  const conceptionDateRef = useRef();
  const projectNameRef = useRef();
  const relatedToRef = useRef();
  const industryRef = useRef();
  const classificationRef = useRef();
  const keywordsRef = useRef();
  const { invention, isReadOnly } = useContext(InventionContext);

  const isValid = () => {
    const year = new Date(conceptionDateRef.current.value).getYear();
    const isValid =
      countryRef.current.checkValidity() &&
      conceptionDateRef.current.checkValidity() &&
      projectNameRef.current.checkValidity() &&
      relatedToRef.current.isValid() &&
      (year > 0 && year < 200)
    return isValid;
  };

  const onDetailsChange = () => {
    setValidity(isValid());
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return {
        country: countryRef.current.value,
        conceptionDate: conceptionDateRef.current.value,
        name: projectNameRef.current.value,
        relatedReferenceNumber: relatedToRef.current.textValue(),
        hasRelatedRef: relatedToRef.current.booleanValue(),
        relatedIndustry: industryRef.current.value,
        productClassification: classificationRef.current.value,
        keywords: keywordsRef.current.value,
      };
    },
  }));

  useEffect(() => {
    countryRef.current.value = invention.country;
    conceptionDateRef.current.value = (invention.conceptionDate) ?
      new Date(invention.conceptionDate.substr(0, 10)).toISOString().substr(0, 10) : null;
    projectNameRef.current.value = invention.name;
    relatedToRef.current.setValue(invention.hasRelatedRef, invention.relatedReferenceNumber);
    industryRef.current.value = invention.relatedIndustry;
    classificationRef.current.value = invention.productClassification;
    keywordsRef.current.value = invention.keywords;
    onDetailsChange();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FilledTextField label="Country where invention originated:"
          inputRef={countryRef}
          required={true}
          readOnly={isReadOnly}
          maxLength={128}
          defaultLen={invention?.country?.length}
          onChange={onDetailsChange} />
      </Grid>
      <Grid item xs={6}>
        <FilledTextField label="Approximate Conception Date:"
          inputRef={conceptionDateRef}
          required={true}
          type="date"
          readOnly={isReadOnly}
          onChange={onDetailsChange} />
      </Grid>
      <Grid item xs={6}>
        <FilledTextField label="Name of Development Project:"
          inputRef={projectNameRef}
          required={true}
          readOnly={isReadOnly}
          maxLength={128}
          defaultLen={invention?.name?.length}
          onChange={onDetailsChange} />
      </Grid>
      <Grid item xs={12}>
        <BooleanTextField label="Is this Invention Disclosure related to another Invention Disclosure,
        Patent Application or other internal reference? If 'Yes', pls identify
        the reference number, else mark 'No':"
          ref={relatedToRef}
          required={true}
          multiline
          maxLength={2000}
          rows={3}
          readOnly={isReadOnly}
          onDataChanged={onDetailsChange} >
        </BooleanTextField>
      </Grid>
      <Grid item xs={6}>
        <FilledTextField maxLength={128} defaultLen={invention?.relatedIndustry?.length} readOnly={isReadOnly} label="Trimble Industry this invention is most relevant to:" inputRef={industryRef} />
      </Grid>
      <Grid item xs={6}>
        <FilledTextField maxLength={128} defaultLen={invention?.productClassification?.length} readOnly={isReadOnly} label=" Product Classification that best fits this invention:" inputRef={classificationRef} />
      </Grid>
      <Grid item xs={12}>
        <FilledTextField maxLength={256} defaultLen={invention?.keywords?.length} readOnly={isReadOnly} label=" Keywords (i.e. optics, RTK, etc.):" inputRef={keywordsRef} />
      </Grid>
    </Grid>
  );
});
