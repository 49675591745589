import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { Grid, makeStyles, Radio, Typography } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { RatingRadioGroup } from "../../shared/RatingRadioGroup";
import InventionContext from "../../shared/InventionContext";

const useStyles = makeStyles({
  group: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  }
});

export const Benefits = React.forwardRef(({ setValidity }, ref) => {
  const [isForInternalPurposeOnly, setisForInternalPurposeOnly] = useState("");
  const [reverseEngineeringRating, setreverseEngineeringRating] = useState("");
  const [commercializationRating, setcommercializationRating] = useState("");
  const [valuetoEnduser, setvaluetoEnduser] = useState("");
  const { invention, isReadOnly } = useContext(InventionContext);
  const classes = useStyles();

  const OnPurposeOnlychange = (event) => {
    setisForInternalPurposeOnly(event.target.value);
  };

  const OnReverseEngineeringRatingchange = (event) => {
    setreverseEngineeringRating(event.target.value);
  };

  const OnCommercializationRatingchange = (event) => {
    setcommercializationRating(event.target.value);
  };

  const OnValuetoEnduserchange = (event) => {
    setvaluetoEnduser(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return {
        isForInternalPurposeOnly: isForInternalPurposeOnly,
        reverseEngineeringRating: reverseEngineeringRating,
        commercializationRating: commercializationRating,
        valuetoEnduser: valuetoEnduser,
      };
    },
  }));

  useEffect(() => {
    setisForInternalPurposeOnly(invention.isForInternalPurposeOnly);
    setreverseEngineeringRating(invention.reverseEngineeringRating);
    setcommercializationRating(invention.commercializationRating);
    setvaluetoEnduser(invention.valuetoEnduser);
    setValidity(true);
  }, []);

  return (
    <Grid container spacing={4} direction="column">
      <Grid item xs={12}>
        <Typography variant="subtitle2">Is this invention for internal purposes only (i.e not to be offered to
        external customers but just for internal operations):
      </Typography>
        <RadioGroup className={classes.group} value={isForInternalPurposeOnly} onChange={OnPurposeOnlychange}        >
          <FormControlLabel value="yes" control={<Radio disabled={isReadOnly} />} label={<Typography variant="subtitle2">Yes</Typography>} />
          <FormControlLabel value="no" control={<Radio disabled={isReadOnly} />} label={<Typography variant="subtitle2">No</Typography>} />
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        <RatingRadioGroup readOnly={isReadOnly} value={reverseEngineeringRating}
          onChange={OnReverseEngineeringRatingchange} label="How easy is it to reverse engineer this invention?"></RatingRadioGroup>
      </Grid>

      <Grid item xs={12}>
        <RatingRadioGroup readOnly={isReadOnly} value={commercializationRating}
          onChange={OnCommercializationRatingchange} label="What is the commercialization potential?"></RatingRadioGroup>
      </Grid>

      <Grid item xs={12}>
        <RatingRadioGroup readOnly={isReadOnly} value={valuetoEnduser}
          onChange={OnValuetoEnduserchange} label="Estimated benefit/value to end user?"></RatingRadioGroup>
      </Grid>
    </Grid>
  );
});
