import React, { useState } from "react";
import { Popover, Typography } from "@material-ui/core"

export const CustomPopover = ({ children, content }) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    return (
        <>
            {React.cloneElement(children, { onClick: handleClick })}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
               {content}
            </Popover>
        </>
    )
}