import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  getInvention,
  getSectors,
  updateInventionAsDraft,
  updateInventionStage,
} from "../api/inventionsApi";
import InventionContext from "../shared/InventionContext";
import SnackbarContext from "../shared/SnackbarContext";
import { InventionStage } from "./inventionStage";
import { InventionStageDetails } from "./InventionStageDetails";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  confirm: theme.confirm,
  progress: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgb(0,0,0,0.1)",
  },
  normalText: {
    fontWeight: "normal",
  },
  stageDetails: {
    marginBottom: 24,
  },
}));

export function Invention(props) {
  const LAST_STAGE_NUMBER = 7;
  const match = useRouteMatch();
  const id = match.params["id"];
  const [stageNumber, setStageNumber] = useState();
  const [invention, setInvention] = useState({});
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const message = useContext(SnackbarContext);
  const history = useHistory();
  const classes = useStyles();
  const [showProgress, setShowProgress] = useState(false);

  const location = useLocation();
  const [showReview, setshowReview] = useState(false);

  useEffect(() => {
    async function fetchInventions() {
      setLoading(true);
      setShowProgress(true);
      const [resp] = await Promise.all([getInvention(id)]);
      setLoading(false);
      setShowProgress(false);
      if (!resp) {
        message.showMessage("Draft not found.");
        history.push("/");
      }
      setInvention(resp);
      if (resp.status === 1) {
        setStageNumber(resp.completedStageNumber + 1);
      } else {
        setStageNumber(1);
      }
    }

    setshowReview(location.state != null && location.state.isReview);

    fetchInventions();
  }, []);

  const onNextStage = (payLoad) => {
    if (stageNumber === LAST_STAGE_NUMBER) {
      confirm({
        dialogProps: {
          maxWidth: "xs",
          className: classes.confirm,
        },
        title: "Submit Application?",
        description:
          "Are you sure you want to submit? You can’t edit once submitted.",
        cancellationText: "Cancel",
        confirmationText: "Submit",
        confirmationButtonProps: { variant: "outlined" },
        cancellationButtonProps: { color: "primary" },
      }).then(() => {
        setShowProgress(true);
        updateInventionStage(id, stageNumber, payLoad)
          .then((resp) => {
            message.showMessage(
              `Application Submitted Successfully. Please click here to view the committees calendar.`
            );
            history.push({ pathname: "/" });
          })
          .finally(() => setShowProgress(false));
      });
    } else {
      setShowProgress(true);
      updateInventionStage(id, stageNumber, payLoad)
        .then((resp) => {
          setInvention(resp.data);
          setStageNumber(resp.data.completedStageNumber + 1);
        })
        .catch((error) => {
          message.showMessage(`Save failed. ${error.toJSON().message}`);
        })
        .finally(() => setShowProgress(false));
    }
  };

  const saveAsDraft = (payload) => {
    setShowProgress(true);
    updateInventionAsDraft(id, stageNumber, payload)
      .then((resp) => {
        setInvention(resp.data);
      })
      .finally(() => setShowProgress(false));
  };

  const goToNextStage = () => {
    if (stageNumber < LAST_STAGE_NUMBER) {
      setStageNumber((stageNumber) => stageNumber + 1);
    }
  };

  const goToPreviousStage = () => {
    if (stageNumber > 1) {
      setStageNumber((stageNumber) => stageNumber - 1);
    }
  };

  const navigateToHome = (ev) => {
    ev.preventDefault();
    history.push("/");
  };

  const navigateToSubmission = (ev) => {
    ev.preventDefault();
    history.push("/reviews");
  };

  return (
    <InventionContext.Provider
      value={{
        invention: invention,
        onSaveAsDraft: saveAsDraft,
        onNextStage: onNextStage,
        lastStageNumber: LAST_STAGE_NUMBER,
        setShowProgress: setShowProgress,
        isReadOnly: invention.status !== 1,
      }}
    >
      {loading ? null : (
        <Grid container spacing={2}>
          <Grid item xs={12} container>
            <Breadcrumbs separator={<NavigateNextIcon color="primary" />}>
              <Typography variant="h4">
                <Link href="/" color="primary" onClick={navigateToHome}>
                  Home
                </Link>
              </Typography>
              {showReview ? (
                <Typography variant="h4">
                  <Link href="/" color="primary" onClick={navigateToSubmission}>
                    Submission
                  </Link>
                </Typography>
              ) : null}
              <Typography variant="h4" color="primary">
                {invention.title}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={7}>
            <Breadcrumbs
              separator={
                <Typography className={classes.normalText} variant="h1">
                  |
                </Typography>
              }
            >
              <Typography variant="h1">{invention.title}</Typography>
              <Typography className={classes.normalText} variant="h1">
                {invention.statusDesc}
              </Typography>
            </Breadcrumbs>
            <Typography variant="subtitle2" disabled>
              Technology - {invention.technology}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography align="right" variant="subtitle2">
              {invention.sectorsDesc}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InventionStage
              stageNumber={stageNumber}
              lastCompletedStageNumber={invention.completedStageNumber}
              onChangeStage={setStageNumber}
            />
          </Grid>
          <Grid item xs={12} className={classes.stageDetails}>
            <InventionStageDetails
              stageNumber={stageNumber}
              goToNextStage={goToNextStage}
              goToPreviousStage={goToPreviousStage}
            />
          </Grid>
        </Grid>
      )}
      <Backdrop open={showProgress} className={classes.progress}>
        <CircularProgress variant="indeterminate" />
      </Backdrop>
    </InventionContext.Provider>
  );
}
