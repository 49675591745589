import { SvgIcon } from "@material-ui/core";
import React from 'react';

export function DeleteIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M17 8v11c0 .513-.386.936-.883.993L16 20H8c-.513 0-.936-.386-.993-.883L7 19V8h10zm-7.5 2c-.245 0-.45.177-.492.41L9 10.5v7c0 .276.224.5.5.5.245 0 .45-.177.492-.41L10 17.5v-7c0-.276-.224-.5-.5-.5zm5 0c-.245 0-.45.177-.492.41L14 10.5v7c0 .276.224.5.5.5.245 0 .45-.177.492-.41L15 17.5v-7c0-.276-.224-.5-.5-.5zM12 10c-.245 0-.45.177-.492.41l-.008.09v7c0 .276.224.5.5.5.245 0 .45-.177.492-.41l.008-.09v-7c0-.276-.224-.5-.5-.5zm0-6c2.143 0 2.755.51 2.93 1.166l.034.16.012.082.015.166.007.17L15 6h3v1H6V6h3l.002-.257.007-.169.015-.166.012-.082.034-.16C9.245 4.51 9.857 4 12 4zm.73 1.003H11.27l-.293.007-.243.013c-.515.04-.668.153-.714.465l-.013.146-.005.173L10 6h4l-.002-.289-.004-.082-.014-.145c-.046-.309-.2-.42-.715-.461l-.243-.013-.292-.007z"
                transform="translate(-1485 -515) translate(388.5 271) translate(1096.5 244)" />
        </SvgIcon>
    );
};