import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FilledTextField } from "../../shared/FilledTextField";
import { Checkbox, ListItemText, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { Instructions } from "./instructions";

const useStyle = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialogTitle-root": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff"
    },
    "& .MuiFormControl-root": {
      marginTop: 1
    },
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "calc(100% - 24px)"
    },
    "& .MuiDialogActions-root": {
      padding: "12px 16px",
      "& .MuiButton-root": {
        minWidth: 90,
        fontWeight: "bold",
        textTransform: "none",
        letterSpacing: "1.26px",
        "&.MuiButton-outlinedPrimary": {
          "&.Mui-disabled": {
            border: "1px solid #aeaeb6",
          },
        },
      },
    },
  },
}));

export function NewInvention({ sectors, open, setOpen, invention, onNext }) {
  const classes = useStyle();
  const titleRef = useRef();
  const techRef = useRef();
  const [associatedSectors, setAssociatedSectors] = useState(invention?.sectors || []);
  const [userSector, setUserSector] = useState(invention?.userSector || "");
  const [isValid, setValidity] = useState(true);
  const [mode, setMode] = useState(invention ? "edit" : "instructions");

  const handleClose = () => setOpen(false);
  const createNew = () => {
    if (mode === "instructions") {
      setMode("new");
      setValidity(false);
    } else if (isValid) {
      onNext({
        id: invention?.id,
        title: titleRef.current.value,
        technology: techRef.current.value,
        sectors: associatedSectors,
        userSector: userSector
      });
    }
  };

  useEffect(() => {
    if (mode === "new") {
      validate();
    }
  }, [associatedSectors, userSector]);

  const validate = () => {
    setValidity(
      titleRef.current?.checkValidity() &&
      techRef.current?.checkValidity() &&
      associatedSectors.length > 0 && !!userSector
    );
  };

  const onSectorChange = (ev) => {
    setAssociatedSectors(ev.target.value);
  };

  const onUserSectorChange = (ev) => {
    setUserSector(ev.target.value);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      fullWidth
    >
      <DialogTitle disableTypography={true}>
        <Typography variant="h1">{(invention?.id) ? 'Edit Submission' : 'New Submission'} </Typography>
      </DialogTitle>
      <DialogContent>
        {mode === "instructions" ? (
          <Instructions />
        ) : (
          <>
            <FilledTextField
              autoFocus
              inputRef={titleRef}
              defaultValue={invention?.title}
              defaultLen={invention?.title?.length}
              required
              maxLength={128}
              label="Title"
              onChange={validate}
            />
            <FilledTextField
              inputRef={techRef}
              required
              label="Technology"
              maxLength={128}
              defaultValue={invention?.technology}
              defaultLen={invention?.technology?.length}
              onChange={validate}
            />
            <FilledTextField
              select
              required
              label="Sector to which the applicant belongs to"
              SelectProps={{ value: userSector, onChange: onUserSectorChange }}
            >
              {sectors.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FilledTextField>
            <FilledTextField
              select
              required
              label="Sector to which this submission applies to"
              SelectProps={{
                multiple: true, value: associatedSectors,
                renderValue: (selected) => selected.map(s => sectors.find(sec => sec.value === s).label).join(', '),
                onChange: onSectorChange
              }}
              onChange={validate}
            >
              {sectors.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox color="primary" checked={associatedSectors.indexOf(option.value) > -1} />
                  <ListItemText primary={ option.label} />
                </MenuItem>
              ))}
            </FilledTextField>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={createNew}
          disabled={!isValid}
          variant="outlined"
          color="primary"
        >
          Next
        </Button>
      </DialogActions>
    </Dialog >
  );
}