import React from 'react';

export default React.createContext(
    {
        userName: '',
        email: '',
        idToken: '',
        isLoggedIn: false,
        updateUser: (user) => { },
        updateToken: (access_token, id_token) => { }
    }
);