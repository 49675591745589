import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridMenuIcon } from "@material-ui/data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import { getSubmissionsForReview } from "../api/reviewApi";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router";
import ProgressSubmission from "./progressSubmission";

function Summary({ title, description, ...props }) {
  return (
    <Grid container direction="column" justify="center">
      <Typography style={{ lineHeight: "12px" }} variant="subtitle2">
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
    </Grid>
  );
}

const ProgressionMenu = ({ submission, approve, reject }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const approveSubmission = () => {
    handleClose();
    approve(submission);
  };

  const rejectSubmission = () => {
    handleClose();
    reject(submission);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon color="primary"></MoreVertIcon>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={approveSubmission}>Approve</MenuItem>
        <MenuItem onClick={rejectSubmission}>Reject</MenuItem>
      </Menu>
    </>
  );
};

const useStyle = makeStyles({
  column: {
    "&.MuiDataGrid-cell": {
      paddingLeft: 2,
    },
  },
  progress: {
    zIndex: 1000,
    backgroundColor: "rgb(0,0,0,0.1)",
  },
});

export default function ListReviews() {
  const [submissions, setSubmissions] = useState([]);
  const [showProgressionDialog, setShowProgressionDialog] = useState(false);
  const [progressionAction, setProgressionAction] = useState("");
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const history = useHistory();
  const classes = useStyle();
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    loadSubmissions();
  }, []);

  const loadSubmissions = () => {
    setShowProgress(true);
    getSubmissionsForReview()
      .then((resp) => {
        setSubmissions(resp);
      })
      .finally(() => setShowProgress(false));
  };

  const approve = (submission) => {
    setProgressionAction("approve");
    setSelectedSubmission(submission);
    setShowProgressionDialog(true);
  };

  const reject = (submission) => {
    setProgressionAction("reject");
    setSelectedSubmission(submission);
    setShowProgressionDialog(true);
  };

  const navigateToHome = (ev) => {
    ev.preventDefault();
    history.push("/");
  };

  const actions = useCallback((invention) => {
    history.push({
      pathname: "/inventions/" + invention.inventionId,
      state: { isReview: true },
    });
  }, []);

  return (
    <>
      <Breadcrumbs separator={<NavigateNextIcon color="primary" />}>
        <Typography variant="h4">
          <Link href="/" color="primary" onClick={navigateToHome}>
            Home
          </Link>
        </Typography>
        <Typography variant="h4" color="primary">
          Submissions
        </Typography>
      </Breadcrumbs>
      <div style={{ minHeight: 180, height: (submissions.length + 2) * 60 }}>
        <DataGrid
          getRowId={(row) => row.inventionId}
          columns={[
            {
              field: "actions",
              headerName: " ",
              width: 50,
              cellClassName: classes.column,
              renderCell: (params) =>
                params.row.statusId > 2 ? null : (
                  <ProgressionMenu
                    approve={approve}
                    reject={reject}
                    submission={params.row}
                    onSuccess={loadSubmissions}
                  ></ProgressionMenu>
                ),
            },
            {
              field: "title",
              headerName: "Title",
              width: 210,
              renderCell: (params) => (
                <Tooltip title={params.row.title}>
                  <Typography variant="subtitle2">
                    <Link
                      href="#"
                      underline="always"
                      onClick={() => actions(params.row)}
                    >
                      {params.row.title}
                    </Link>
                  </Typography>
                </Tooltip>
              ),
            },
            {
              field: "technology",
              headerName: "Technology",
              width: 210,
              renderCell: (params) => (
                <Tooltip title={params.row.technology}>
                  <Typography variant="subtitle2">
                    {params.row.technology}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              field: "sectorDesc",
              headerName: "Sector",
              width: 210,
              renderCell: (params) => (
                <Tooltip title={params.row.sectorDesc}>
                  <Typography variant="subtitle2">
                    {params.row.sectorDesc}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              field: "submitted",
              headerName: "Submitted By",
              width: 180,
              renderCell: (params) => (
                <Summary
                  title={params.row.submittedBy.name}
                  description={
                    <Moment
                      date={params.row.dateSubmitted}
                      format="on DD-MMM-yyyy"
                    />
                  }
                />
              ),
            },
            {
              field: "status",
              headerName: "Status",
              width: 150,
              renderCell: (params) =>
                params.row.statusId === 2 ? (
                  <Typography variant="subtitle2">
                    {params.row.statusDesc}
                  </Typography>
                ) : (
                  <Summary
                    title={params.row.statusDesc}
                    description={"by " + params.row.lastProgessedBy.name}
                  />
                ),
            },
            {
              field: "lastProgessedComments",
              headerName: "Comments",
              title: "Comments",
              width: 210,
              renderCell: (params) => (
                <Tooltip title={params.row.lastProgessedComments}>
                  <Typography variant="subtitle2">
                    {params.row.lastProgessedComments}
                  </Typography>
                </Tooltip>
              ),
            },
          ]}
          hideFooter={true}
          rows={submissions}
        ></DataGrid>
        <ProgressSubmission
          action={progressionAction}
          submission={selectedSubmission}
          open={showProgressionDialog}
          setOpen={setShowProgressionDialog}
          onSuccess={loadSubmissions}
        ></ProgressSubmission>
        <Backdrop open={showProgress} className={classes.progress}>
          <CircularProgress variant="indeterminate" />
        </Backdrop>
      </div>
    </>
  );
}
