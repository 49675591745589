import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormLabel, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    helper: ({ multiline }) => ({
        display: "flex",
        paddingLeft: 14,
        paddingRight: 14,
        marginTop: (multiline) ? 0 : -8,
        justifyContent: "space-between",
        width: "100%"
    })
})

export function FilledTextField({ label, required, readOnly, maxLength, error, defaultLen, errorMessage, ...rest }) {
    const [helperText, setHelperText] = useState((required ? '* required' : ''));
    const textRef = useRef(null);
    const { onChange, multiline } = rest;
    const [charLen, setCharLen] = useState(defaultLen || 0);
    const classes = useStyles({ multiline: multiline });

    useEffect(() => {
        setHelperText((error) ? errorMessage : (required ? '* required' : ''));
    }, [error, errorMessage, required]);

    const onValueChange = useCallback((ev) => {
        if (maxLength) {
            setCharLen(ev.target.value.length);
        }
        if (onChange) {
            onChange(ev);
        }
    }, [onChange, maxLength]);

    return (
        <>
            <FormLabel>
                <Typography color="textSecondary" variant="subtitle2">{label}</Typography>
            </FormLabel>
            <TextField ref={textRef} error={!!error} required={required} fullWidth margin="normal" type="text" variant="filled"
                {...rest}
                onChange={onValueChange}
                InputProps={{ readOnly: readOnly, disableUnderline: readOnly }}
                inputProps={{ maxLength: maxLength }}
            />
            {(readOnly) ? null : <div className={classes.helper}>
                <Typography variant="body1">{helperText || ''}</Typography>
                {(maxLength) ? <Typography variant="body1">{charLen}/{maxLength} </Typography> : null}
            </div>
            }
        </>
    )
} 