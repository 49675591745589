import axios from 'axios';
import config from '../config';

const api = axios.create({
    baseURL: config.baseUrl
});

api.interceptors.request.use(function (config) {
    const id_token = window.localStorage.getItem('id-token');
    const accessToken = window.localStorage.getItem('access-token');
    config.headers.Authorization = `Bearer ${id_token}`;
    config.headers['X_access_token'] = accessToken;
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            window.document.location.href = `${config.loginUrl}/?returnUrl=${config.returnUrl}`  
        }
        return Promise.reject(error);
    }
);


export { api };