import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Link,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import AddIcon from "@material-ui/icons/Add";
import { NewInvention } from "./details/newInvention";
import { useHistory } from "react-router-dom";
import {
  createInvention,
  updateInvention,
  deleteInventionById,
  getMyInventions,
  getSectors,
} from "../api/inventionsApi";
import { DeleteIcon } from "../icons/DeleteIcon";
import { useConfirm } from "material-ui-confirm";
import Moment from "react-moment";
import { EditInventionIcon } from "../icons/EditInventionIcon";
import SnackbarContext from "../shared/SnackbarContext";
import { Label } from "@material-ui/icons";
import UserContext from "../authentication/UserContext";

const useStyles = makeStyles((theme) => ({
  separator: {
    color: "#aeaeb6",
    marginTop: 50,
    marginBottom: 30,
  },
  confirm: theme.confirm,
  actions: {
    "& *": {
      cursor: "pointer",
    },
  },
  progress: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgb(0,0,0,0.1)",
  },
  createNew: {
    marginLeft: 12,
    "& .MuiButton-label": {
      fontWeight: "bold",
      letterSpacing: "1.26px",
    },
  },
}));

function CellActions({ row, actions }) {
  const classes = useStyles();
  if (row.status !== 1) return null;

  return (
    <Grid container justify="space-between" className={classes.actions}>
      <EditInventionIcon color="primary" onClick={() => actions("edit", row)} />
      <DeleteIcon color="primary" onClick={() => actions("delete", row)} />
    </Grid>
  );
}

function NoSubmissionsOverlay() {
  return (
    <GridOverlay>
      <Typography variant="subtitle2">No Submissions found</Typography>{" "}
    </GridOverlay>
  );
}

function DeleteConfirmationMessage({ title }) {
  return (
    <div>
      Are you sure to delete the draft <b>{title}</b>?. This action cannot be
      undone.
    </div>
  );
}

export function ListInventions({ location }) {
  const [inventions, setInventions] = useState([]);
  const [inventionToEdit, setInventionToEdit] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [sectors, setSectors] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const confirm = useConfirm();
  const [key, setKey] = useState(0);
  const message = useContext(SnackbarContext);
  const userInfo = useContext(UserContext);

  const openNew = () => {
    setInventionToEdit(null);
    setKey((key) => key + 1);
    setIsOpen(true);
  };

  const listSubmissionsForReview = () => {
    history.push("/reviews");
  };

  useEffect(() => {
    loadSectors();
    loadInventions();
  }, []);

  const loadSectors = () => {
    getSectors().then((resp) => {
      setSectors(
        resp.map((sector) => ({
          value: sector.sectorId,
          label: sector.description,
        }))
      );
    });
  };

  const loadInventions = () => {
    setShowProgress(true);
    getMyInventions()
      .then((resp) =>
        setInventions(
          resp.data.map((d) => ({
            ...d,
            id: d.inventionId,
          }))
        )
      )
      .finally(() => setShowProgress(false));
  };

  const onSubmissionNext = ({ id, title, technology, sectors, userSector }) => {
    if (id) {
      setShowProgress(true);
      updateInvention(id, title, technology, sectors, userSector)
        .then((resp) => {
          const invention = resp.data;
          history.push(`/inventions/${invention.inventionId}`);
          setIsOpen(false);
        })
        .finally(() => setShowProgress(false));
    } else {
      setShowProgress(true);
      createInvention(title, technology, sectors, userSector)
        .then((resp) => {
          const invention = resp.data;
          history.push(`/inventions/${invention.inventionId}`);
          setIsOpen(false);
        })
        .finally(() => setShowProgress(false));
    }
  };

  const actions = useCallback((type, invention) => {
    if (type === "edit") {
      if (invention.status === 1) {
        edit(invention);
      } else {
        history.push(`/inventions/${invention.inventionId}`);
      }
    } else if (type === "delete") {
      deleteInvention(invention);
    }
  }, []);

  const edit = (invention) => {
    setInventionToEdit(invention);
    setKey((key) => key + 1);
    setIsOpen(true);
  };

  const deleteInvention = (invention) => {
    confirm({
      dialogProps: {
        maxWidth: "xs",
        className: classes.confirm,
      },
      title: "Delete confirmation",
      description: <DeleteConfirmationMessage title={invention.title} />,
      confirmationButtonProps: { variant: "outlined" },
      cancellationButtonProps: { color: "primary" },
    }).then((data) => {
      setShowProgress(true);
      deleteInventionById(invention.inventionId)
        .then(
          () => {
            message.showMessage(`${invention.title} deleted successfully.`);
            loadInventions();
          },
          (err) => {
            message.showMessage(`Unable to delete ${invention.title}.\n${err}`);
          }
        )
        .finally(() => setShowProgress(false));
    });
  };

  return (
    <>
      <Grid container spacing={1} justify="center">
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h1">
              Welcome to My Trimbe Innovation
            </Typography>
            <Typography variant="subtitle2" disabled>
              Submit your Trimble Invention applications for patent filing.
            </Typography>
          </Grid>
          <Grid item container xs={4} alignItems="flex-end" justify="flex-end">
            {userInfo.isApprover ? (
              <Button
                variant="contained"
                className={classes.createNew}
                color="primary"
                onClick={listSubmissionsForReview}
              >
                Reviewer
              </Button>
            ) : null}
            <Button
              variant="contained"
              className={classes.createNew}
              color="primary"
              startIcon={<AddIcon />}
              onClick={openNew}
            >
              Create New Submission
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <hr className={classes.separator}></hr>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">My Submissions</Typography>
          <div style={{ height: (inventions.length + 2) * 60 }}>
            <DataGrid
              key={1}
              components={{
                noRowsOverlay: NoSubmissionsOverlay,
              }}
              columns={[
                { field: "id", headerName: "ID", width: 70, key: "id" },
                {
                  field: "title",
                  headerName: "Title",
                  width: 210,
                  renderCell: (params) => (
                    <Tooltip title={params.row.title}>
                      <Typography variant="subtitle2">
                        <Link
                          href="#"
                          underline="always"
                          onClick={() => actions("edit", params.row)}
                        >
                          {params.row.title}
                        </Link>
                      </Typography>
                    </Tooltip>
                  ),
                },
                {
                  field: "technology",
                  headerName: "Technology",
                  width: 210,
                  renderCell: (params) => (
                    <Tooltip title={params.row.technology}>
                      <Typography variant="subtitle2">
                        {params.row.technology}
                      </Typography>
                    </Tooltip>
                  ),
                },
                {
                  field: "sectorDesc",
                  headerName: "Sector",
                  width: 230,
                  renderCell: (params) => (
                    <Tooltip title={params.row.sectorsDesc}>
                      <Typography variant="subtitle2">
                        {params.row.sectorsDesc}
                      </Typography>
                    </Tooltip>
                  ),
                },
                {
                  field: "dateSubmitted",
                  headerName: "Date Submitted",
                  width: 150,
                  renderCell: (params) =>
                    params.row.dateSubmitted ? (
                      <Moment
                        date={params.row.dateSubmitted}
                        format="DD-MMM-yyyy"
                      />
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "presentedOn",
                  headerName: "Presented On",
                  width: 150,
                  renderCell: (params) =>
                    params.row.presentedOn ? (
                      <Moment
                        date={params.row.presentedOn}
                        format="DD-MMM-yyyy"
                      />
                    ) : (
                      "-"
                    ),
                },
                { field: "statusDesc", headerName: "Status", width: 110 },
                {
                  field: "actions",
                  headerName: " ",
                  width: 100,
                  disableClickEventBubbling: true,
                  renderCell: (params) => (
                    <CellActions row={params.row} actions={actions} />
                  ),
                },
              ]}
              rows={inventions}
              hideFooter={true}
            ></DataGrid>
          </div>
        </Grid>
      </Grid>
      <Backdrop open={showProgress} className={classes.progress}>
        <CircularProgress variant="indeterminate" />
      </Backdrop>
      <NewInvention
        invention={inventionToEdit}
        key={key}
        sectors={sectors}
        open={isOpen}
        setOpen={setIsOpen}
        onNext={onSubmissionNext}
      />
    </>
  );
}
