import { api } from "./api";
const baseUrl = "/reviewer";

export function getSubmissionsForReview() {
  return api.get(`${baseUrl}/submissions`).then((resp) => resp.data);
}

export function approveSubmission(inventionId, comment) {
  return api
    .post(`${baseUrl}/approve/${inventionId}`, {
      inventionId: inventionId,
      comments: comment,
    });
}

export function rejectSubmission(inventionId, comment) {
  return api
    .post(`${baseUrl}/reject/${inventionId}`, {
      inventionId: inventionId,
      comments: comment,
    });
}
