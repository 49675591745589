import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { StageNumber } from "../shared/stageNumber";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#f9f9fb",
    height: 128,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.11)",
    borderRadius: 5,
  },
});

export function InventionStage({
  stageNumber,
  lastCompletedStageNumber,
  onChangeStage,
}) {
  const classes = useStyles();
  const getState = (stage) => {
    if (stageNumber === stage) return "active";
    if (stage > lastCompletedStageNumber) return "pending";
    if (stage <= lastCompletedStageNumber) return "completed";
  };

  const changeStage = (newStage) => {
    onChangeStage(newStage);
  };

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid container item wrap="nowrap">
        <StageNumber title="General " stageNumber="01" state={getState(1)} changeStage={() => changeStage(1)}></StageNumber>
        <StageNumber title="Inventor(s)" stageNumber="02" state={getState(2)} changeStage={() => changeStage(2)}></StageNumber>
        <StageNumber title="Invention History" stageNumber="03" state={getState(3)} changeStage={() => changeStage(3)}></StageNumber>
        <StageNumber title="Description" stageNumber="04" state={getState(4)} changeStage={() => changeStage(4)}></StageNumber>
        <StageNumber title="Benefits Estimation" stageNumber="05" state={getState(5)} changeStage={() => changeStage(5)}></StageNumber>
        <StageNumber title="Links/Documents" stageNumber="06" state={getState(6)} changeStage={() => changeStage(6)}></StageNumber>
        <StageNumber title="Comments" stageNumber="07" state={getState(7)} changeStage={() => changeStage(7)}></StageNumber>
      </Grid>
    </Grid>
  );
}
